import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Cling from "../../components/Portfolio/Cling"

const ClingPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Cling />
  </Layout>
)

export default ClingPage
